@import "./components/variables";
@import "./components/sizing";
@import "./components/layout";
@import "./components/margin";
@import "./components/animations";
@import "./components/typography";
@import "./components/borders";
@import "./components/backgrounds";
@import "./components/images";
@import "./components/buttons";
@import "./components/icons";
@import "./components/positions";
@import "./components/progress";
@import "./components/tables";
@import "./components/pagination";

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.loading-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: #ffffffc7;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  width: 100%;
  margin: 0;
}

a {
  all: unset;
}

:root {
  --swiper-theme-color: rgba(255, 255, 255, 0.5) !important;
}

.dropdown-item:hover {
  background-color: #102660;
  color: white;
}
