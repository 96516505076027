.mt-100px {
  margin-top: 100px;
}

.mt-50px {
  margin-top: 50px;
}

@media (max-width: 480px) {
  .additional-desc {
    margin-top: 80px !important;
  }
}
