.border-md-start {
  //   @extend .border-start;
  border-left: 1px solid rgba(108, 117, 125, 0.3);
}

.border-md-end {
  //   @extend .border-end;
  border-right: 1px solid rgba(108, 117, 125, 0.3);
}

@media (max-width: 800px) {
  .border-md-start,
  .border-md-end {
    border-right: 0;
    border-left: 0;
  }
}
