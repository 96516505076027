.wh-20px {
  width: 20px;
  height: 20px;
}

.wh-25px {
  width: 25px;
  height: 25px;
}

.min-h-50vh {
  min-height: 50vh;
}

.min-h-60vh {
  min-height: 60vh;
}

.w-120px {
  width: 120px;
}

.wh-50px {
  width: 50px;
  height: 50px;
}

.footer-grid-columns {
  grid-template-columns: 1fr 1fr;
}

.h-300px {
  height: 300px;
}
.w-100px {
  width: 100px;
}

.w-200px {
  width: 200px;
}

.h-400px {
  height: 400px;
}

.h-50vh {
  height: 50vh;
}

.wh-100px {
  width: 100px;
  height: 100px;
}

.h-500px {
  height: 500px;
}

.h-550px {
  height: 550px;
}

.h-400px {
  height: 400px;
}

.wh-30px {
  width: 30px;
  height: 30px;
}

.max-w-400 {
  max-width: 400px;
}

.w-fill {
  width: -webkit-fill-available;
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.h-400px {
  height: 400px;
}

.h-200px {
  height: 200px;
}

.w-300px {
  width: 300px;
}

.h-70px {
  height: 70px;
}

@media (max-width: 480px) {
  .mt-100px {
    margin-top: 70px;
  }

  .h-300px {
    height: 250px;
  }
}
