.landing-background {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover;
  height: 60vh;
  display: flex;
  align-items: center;
}

@media (max-width: 480px) {
  .landing-background {
    height: 60vh;
  }
}

.bg-blur-glass {
  background: rgb(13, 14, 14, 0.47);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.8px);
  -webkit-backdrop-filter: blur(6.8px);
  border: 1px solid rgba(16, 29, 33, 0.3);
}

.contact-home-bg {
  background-color: rgba(226, 225, 225, 0.6);
}

.bg-gray {
  background-color: rgba(226, 225, 225, 0.6);
}

.box-shadow-impressions {
  box-shadow: 1px 1px 14px 4px #eeeeee;
}

.bg-blur {
  filter: blur(4px);
}

@media (max-width: 480px) {
  .bg-text {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px;
  }
}
