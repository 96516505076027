.img-position {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  &.first-w {
    top: 50%;
  }

  &.second-w {
    top: 90%;
  }

  &.first-m {
    top: 30%;
  }

  &.second-m {
    top: 90%;
  }
}

.product-order-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.product-img-big {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.opacity-5 {
  opacity: 0.5;
}

.about-logo {
  width: 25%;
}

.product-img-sm {
  @extend .product-order-img;
  height: 100px;
}

@media (max-width: 768px) {
  .img-position {
    &.first-w {
      top: 40% !important;
    }

    &.second-m {
      top: 80% !important;
    }

    &.first-m {
      top: 20%;
    }

    &.second-w {
      top: 80%;
    }
  }
  .about-logo {
    width: 50%;
  }
}

.quill-content img {
  width: 100%;
  object-fit: contain;
  max-height: 500px;
}

.quill-content {
  padding-left: 5px;
  padding-right: 5px;
}
