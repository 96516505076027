.top-right-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
}

.promo-badge-nav {
  position: absolute;
  z-index: 1;
  top: -8px;
  right: 30px;
}

.add-to-cart-product {
  bottom: 0;
}

.contact-position {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
  .add-to-cart-product {
    bottom: -30%;
  }
  .promo-badge-nav {
    right: -15px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .add-to-cart-product {
    bottom: -21%;
  }
}
