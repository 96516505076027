.hover-underline-animation {
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $dark;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
}

.slide-in-text-left {
  opacity: 0;
  transform: translateX(-100%);
  animation: slide-in 1s ease-out forwards;
}

.slide-in-text-right {
  opacity: 0;
  transform: translateX(100%);
  animation: slide-in 1s ease-out forwards;
}

@keyframes slide-in {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.underline-text {
  display: inline-block;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--bs-primary);
    transform-origin: bottom right;
  }
}

.text-primary {
  .hover-underline-animation {
    &::after {
      background-color: $secondary;
    }
  }

  &.hover-underline-animation {
    &::after {
      background-color: $secondary;
    }
  }
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-animation {
  transition: all 0.15s ease-in-out;
}

.hamburger {
  display: none;
}

.hamburger div {
  background: #102660 !important;
  height: 3px;
  margin: 5px;
  transition: all 0.3s ease;
  width: 30px;
}

.hamburger.toggle div {
  background-color: #fff !important;
}

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  transition: all 0.7s ease;
  width: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 768px) {
  .hamburger {
    cursor: pointer;
    display: block;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(-5%, -50%);
    transition: all 0.7s ease;
    z-index: 2;
  }

  @keyframes slidein {
    0% {
      top: 100%;
      opacity: 0;
    }

    50% {
      top: 50%;
      opacity: 0;
    }

    100% {
      top: 20%;
      opacity: 1;
    }
  }
}
