.product-card {
  .product-side-buttons {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    position: absolute;
    top: 75px;
    right: 0;
    background-color: transparent;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    .wishlist,
    .cart,
    .zoomin {
      transition: 0.5s;
      visibility: hidden;
      opacity: 0;
      transform: translate(30px);
      margin-bottom: 15px;
    }
    .product-side-btn {
      color: #232529;
      display: inline-block;
      width: 46px;
      height: 46px;
      line-height: 46px;
      border-radius: 50%;
      padding: 0;
      font-weight: 400;
      margin: 0 10px;
      background-color: #fff;
      font-size: 0;
      box-shadow: 0 3px 10px #00000014;
      transition: 0.3s;
      text-align: center;
      border: none;
      margin-bottom: 10px;
    }
  }
  &:hover {
    .product-side-buttons {
      .wishlist,
      .cart,
      .zoomin {
        transform: none;
        transition: 0.5s;
        opacity: 1;
        visibility: visible;
      }
      .wishlist {
        transition-delay: 0.1s;
      }

      .cart {
        transition-delay: 0.2s;
      }

      .zoomin {
        transition-delay: 0.3s;
      }
    }
  }
}

.product-button {
  position: absolute;
  top: 50%;
  z-index: 1;
  border: none;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #dad9d9;
  }
}

.cart-btn {
  background-color: transparent;
  border: none;
  padding: 10px 20px;

  &:disabled {
    cursor: not-allowed;
  }
}

.product-btn {
  margin-top: 5px;
  // background: transparent;
  background: white;
  border: 1px solid rgb(165, 164, 164);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-save-btn {
  width: 25%;
}

@media (max-width: 480px) {
  .w-save-btn {
    width: 50%;
  }
}
