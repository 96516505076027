.dashboard-icons {
  width: 25px;
  height: 25px;
  color: #102660;
}

.checkout-success {
  width: 90px;
  height: 90px;
}

.promo-badge {
  position: absolute;
  right: 10px;
  // bottom: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-radius: 0;
}
