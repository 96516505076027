* {
  font-family: "Lato", sans-serif;
}

.text-img-font {
  font-family: "Marcellus", serif;
}

.fs-13px {
  font-size: 13px;
}

.text-decoration {
  text-decoration: underline white 2px;
}

.fs-20px {
  font-size: 20px;
}

.fs-30px {
  font-size: 30px;
}

.fs-25px {
  font-size: 25px;
}

@media (max-width: 480px) {
  .company-desc {
    .fs-20px {
      font-size: 15px;
    }
  }
}
