$theme-colors: (
  "primary": #102660,
  // "success": #198754,
  "beige": #f5f4f0,
  "grey": #dbd9da,
  "danger": #c32929,
);

@import "~bootstrap/scss/bootstrap";

:root {
  --primary: #102660;
}
