.pagination {
  .page-item {
    .page-link {
      color: var(--primary);
      z-index: 1;

      &:focus {
        box-shadow: none;
      }
    }

    &.active {
      .page-link {
        color: $white;
        background-color: var(--primary);
        border-color: var(--primary);
      }
    }
  }
}
